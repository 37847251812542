<template>
  <div class="content">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>智能出卷</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <div class="left-top">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiagoufenxiao" />
          </svg>
          <span>岗位选择</span>
        </div>
        <div>
          <el-tree
            ref="nodeTree"
            node-key="id"
            @node-click="select"
            highlight-current
            :data="treeData"
            :props="defaultProps"
            :style="{ height: th - 20 + 'px' }"
            style="overflow-y: auto"
          >
            <div class="custom-tree-node" slot-scope="{ node, data }">
              <Tooltip :content="node.label" :transfer="true" max-width="200">
                <div class="desc">
                  {{ node.label.length > 15 ? node.label.substr(0, 15) + '...' : node.label }}
                </div>
              </Tooltip>
            </div>
          </el-tree>
        </div>
      </div>
      <div class="right">
        <div class="form" :style="{ 'max-height': th + 'px' }">
          <div class="top" id="top">
            <span>设置出卷规则</span>
            <Button
              :disabled="ruleForm.postId === ''"
              type="primary"
              icon="ios-eye-outline"
              @click="
                $router.push({
                  name: 'rulePaperList',
                  params: { id: ruleForm.postId }
                })
              "
            >
              查看该规则下试卷
            </Button>
          </div>
          <div class="item">
            <div class="title">题目难度</div>
            <div style="display: flex">
              <div class="dif" @click="ruleForm.difficulty = 0" :class="ruleForm.difficulty === 0 ? 'choose' : ''">
                容易
              </div>
              <div class="dif" @click="ruleForm.difficulty = 1" :class="ruleForm.difficulty === 1 ? 'choose' : ''">
                一般
              </div>
              <div class="dif" @click="ruleForm.difficulty = 2" :class="ruleForm.difficulty === 2 ? 'choose' : ''">
                困难
              </div>
            </div>
            <Divider />
            <div style="display: flex">
              <div style="margin-right: 200px">
                <div class="title">通过率(0-1)</div>
                <InputNumber v-model="ruleForm.passRate" :max="1" :min="0" :step="0.1" />
              </div>
              <div>
                <div class="title">总数量</div>
                <InputNumber v-model="ruleForm.questionCount" :min="1" />
              </div>
            </div>
            <Divider />
            <div style="cursor: pointer;color: #568FEB;font-weight: 400;font-size: 14px" type="text" @click="showMore = !showMore">
              {{ showMore ? '隐藏...' : '更多...' }}
            </div>
            <div v-if="showMore && ruleForm.questionRateFormList.length > 0">
              <div style="display: flex;margin-top: 16px">
                <div style="margin-right: 200px">
                  <div class="title">单选题占比(0-1)</div>
                  <InputNumber v-model="ruleForm.questionRateFormList[1].questionRate" :max="1" :min="0" :step="0.1" />
                </div>
                <div>
                  <div class="title">多选题占比(0-1)</div>
                  <InputNumber v-model="ruleForm.questionRateFormList[2].questionRate" :max="1" :min="0" :step="0.1" />
                </div>
              </div>
              <Divider />
              <div style="display: flex">
                <div style="margin-right: 200px">
                  <div class="title">填空题占比(0-1)</div>
                  <InputNumber v-model="ruleForm.questionRateFormList[3].questionRate" :max="1" :min="0" :step="0.1" />
                </div>
                <div>
                  <div class="title">判断题占比(0-1)</div>
                  <InputNumber v-model="ruleForm.questionRateFormList[0].questionRate" :max="1" :min="0" :step="0.1" />
                </div>
              </div>
              <Divider />
              <div style="display: flex">
                <!--                <div style="margin-right: 200px">-->
                <!--                  <div class="title">简答题占比(0-1)</div>-->
                <!--                  <InputNumber v-model="ruleForm.questionRateFormList[4].questionRate" :max="1" :min="0" :step="0.1"/>-->
                <!--                </div>-->
                <div>
                  <div class="title">代码题占比(0-1)</div>
                  <InputNumber v-model="ruleForm.questionRateFormList[4].questionRate" :max="1" :min="0" :step="0.1" />
                </div>
              </div>
              <Divider />
              <div class="title">设置岗位下能力权重(0-1)</div>
              <div v-if="ruleForm.abilityRateFormList.length !== 0" style="position: relative;width: 100%" id="main">
                <div id="myChart" style="width: 100%;height: 100%"></div>
                <div
                  style="display: flex;position: absolute;align-items: center;background: white"
                  :style="{ width: calcWidth(index) }"
                  v-for="(item, index) in inputPosition"
                  :key="index"
                  :id="'input' + index"
                >
                  <div style="font-size: 14px;color: #3A4E64;white-space: nowrap;margin-right: 8px" v-if="ruleForm.abilityRateFormList[index]">
                    &nbsp;{{ item.name }}
                  </div>
                  <!--                  <div>{{ruleForm.abilityRateFormList[1].abilityRate}}</div>-->
                  <!--                  <InputNumber v-model="item.abilityRate" :max="1" :min="0" :step="0.1"/>-->
                  <InputNumber
                    v-if="ruleForm.abilityRateFormList[index]"
                    v-model="ruleForm.abilityRateFormList[index].abilityRate"
                    :max="1"
                    :min="0"
                    :step="0.1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <!--          <Button style="margin-right: 20px">取消</Button>-->
          <Button @click="confirm" type="primary" :loading="loading">确定</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import functionApi from '@api/functionApi'
import paper from '@api/paper'

export default {
  name: 'Intelligence',
  data() {
    return {
      xWidth: [],
      postName: '',
      ruleForm: {
        abilityRateFormList: [],
        difficulty: 0,
        passRate: 0.6,
        postId: '',
        questionCount: 100,
        questionRateFormList: [
          { questionRate: 0.5, questionType: 0 },
          { questionRate: 0.5, questionType: 1 },
          { questionRate: 0.5, questionType: 2 },
          { questionRate: 0.5, questionType: 3 },
          // { questionRate: 0.5, questionType: 4 },
          { questionRate: 0.5, questionType: 5 }
        ]
      },
      showMore: false,
      loading: false,
      th: document.body.clientHeight - 195,
      defaultProps: {
        children: 'posts',
        label: 'name'
      },
      treeData: [],
      abTree: {
        series: [
          {
            type: 'tree',
            id: 0,
            name: '能力权重',
            data: [],
            top: '0',
            left: '20%',
            bottom: '0',
            right: '20%',
            symbol: 'circle',
            symbolSize: 0.1,
            edgeShape: 'polyline',
            lineStyle: {
              width: 1,
              color: '#3A4E64'
            },
            label: {
              color: '#3A4E64',
              fontSize: 14,
              position: 'left',
              verticalAlign: 'middle',
              align: 'right'
            },
            leaves: {
              label: {
                show: false
              }
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      },
      inputPosition: []
    }
  },
  created() {
    this.initTreeData()
  },
  watch: {
    showMore(val) {
      if (val && this.ruleForm.postId) {
        this.getAbTree(true)
      }
    }
  },
  methods: {
    calcWidth(index) {
      return document.getElementById('top').clientWidth - this.xWidth[index] + 'px'
    },
    select(val) {
      this.ruleForm.postId = val.id
      this.postName = val.name
      paper.getRule(val.id).then(res => {
        this.showMore = false
        if (res.res) {
          this.$nextTick(() => {
            this.ruleForm.abilityRateFormList = res.res.abilityRateVOS || []
          })
          this.ruleForm.questionRateFormList = res.res.questionTypeRateVOS || [
            { questionRate: 0.5, questionType: 0 },
            { questionRate: 0.5, questionType: 1 },
            { questionRate: 0.5, questionType: 2 },
            { questionRate: 0.5, questionType: 3 },
            // { questionRate: 0.5, questionType: 4 },
            { questionRate: 0.5, questionType: 5 }
          ]
          this.ruleForm.passRate = res.res.passRate || 0.6
          this.ruleForm.questionCount = res.res.questionCount || 100
          this.ruleForm.difficulty = res.res.difficulty || 0
        } else {
          this.ruleForm = {
            abilityRateFormList: [],
            difficulty: 0,
            passRate: 0.6,
            postId: val.id,
            questionCount: 100,
            questionRateFormList: [
              { questionRate: 0.5, questionType: 0 },
              { questionRate: 0.5, questionType: 1 },
              { questionRate: 0.5, questionType: 2 },
              { questionRate: 0.5, questionType: 3 },
              // { questionRate: 0.5, questionType: 4 },
              { questionRate: 0.5, questionType: 5 }
            ]
          }
        }
        if (this.showMore) {
          this.getAbTree(res.res.abilityRateVOS)
        }
      })
    },
    getAbTree(ability) {
      functionApi.getAbility(this.ruleForm.postId).then(res => {
        let r = res.res.map(item => {
          item.id = item.abilityId
          return item
        })
        let data = {
          name: this.postName,
          children: r
        }
        this.abTree.series[0].data = [data]
        let myChart = this.$echarts.init(document.getElementById('myChart'))
        myChart.setOption(this.abTree)
        let container = document.getElementById('main')
        let allNode = 0
        let nodes = myChart._chartsViews[0]._data._graphicEls
        for (let i = 0, count = nodes.length; i < count; i++) {
          let node = nodes[i]
          if (node === undefined) continue
          allNode++
        }
        let height = 430
        let currentHeight = 25 * allNode
        let newWidth = Math.max(currentHeight, height)
        if (height > currentHeight && height - currentHeight < 100) {
          container.style.height = newWidth + 150 + 'px'
        } else {
          container.style.height = newWidth + 'px'
        }
        myChart.resize()
        let p = myChart.getViewOfSeriesModel(myChart.getModel().getSeries()[0])
        this.inputPosition = p._data._itemLayouts.filter((item, index) => {
          return index > 1
        })
        if (!ability) {
          this.ruleForm.abilityRateFormList = []
          p._data._idList.forEach((item, index) => {
            if (index > 1) {
              this.ruleForm.abilityRateFormList.push({
                abilityId: item,
                abilityRate: 0.5
              })
            }
          })
        }
        p._data._nameList.forEach((item, index) => {
          if (index > 1) {
            this.inputPosition[index - 2].name = item
          }
        })
        this.xWidth = []
        this.inputPosition.forEach((item, index) => {
          this.xWidth[index] = item.x
        })
        this.$nextTick(() => {
          this.inputPosition.forEach((item, index) => {
            this.xWidth[index] = item.x
            document.getElementById(`input${index}`).style.top = item.y - 15 + 'px'
            document.getElementById(`input${index}`).style.left = item.x + 'px'
          })
        })
      })
    },
    confirm() {
      this.loading = true
      paper
        .addRule(this.ruleForm)
        .then(res => {
          this.loading = false
          this.$message.success('设置成功')
        })
        .catch(() => {
          this.loading = false
        })
    },
    initTreeData() {
      functionApi.getAllPostDataById(0).then(res => {
        this.treeData = res.res
        if (this.treeData.length > 0) {
          this.$nextTick(() => {
            this.select(this.treeData[0])
            this.$refs.nodeTree.setCurrentKey(this.treeData[0].id)
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.content {
  .main {
    display: flex;
    padding: 10px 20px;
    height: 100%;

    .left {
      width: 303px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
      opacity: 1;

      .top {
        height: 50px;
        background: rgba(228, 233, 239, 1);
        opacity: 1;
        color: #3a4e64;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding: 0 20px;

        span {
          margin-left: 12px;
        }
      }
    }

    .right {
      margin-left: 20px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
      opacity: 1;
      flex: auto;
      position: relative;

      .form {
        padding: 40px 50px;
        text-align: left;
        overflow: auto;

        .item {
          margin-top: 20px;

          .title {
            color: #3a4e64;
            font-weight: 400;
            font-size: 14px;
            margin-bottom: 10px;
          }

          .dif {
            text-align: center;
            width: 48px;
            height: 28px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(210, 210, 210, 1);
            opacity: 1;
            border-radius: 4px;
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            color: rgba(86, 143, 235, 1);
            margin-right: 20px;
            cursor: pointer;
          }

          .choose {
            background: rgba(86, 143, 235, 1);
            color: white;
            border: none;
          }
        }

        .top {
          display: flex;
          justify-content: space-between;

          span {
            font-size: 16px;
            font-weight: 400;
            color: rgba(43, 52, 61, 1);
          }

          .btn {
            background-color: #415e8d;
            color: #fffefe;
          }
        }
      }

      .foot {
        width: 100%;
        height: 50px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.03);
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        position: absolute;
      }
    }
  }
}
</style>
